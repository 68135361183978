<template>
    <v-autocomplete
        ref="field"
        v-model="inventoryItem"
        :items="items"
        :loading="isLoading"
        hide-no-data
        hide-selected
        item-text="name"
        label="Inventario"
        placeholder=""
        return-object
        auto-select-first
    >
        <template v-slot:item="item">
            <v-list-item-content>
                <v-list-item-title v-text="item.item.name"></v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
                <span>
                    {{ item.currentStock }}
                </span>
            </v-list-item-action>
        </template>
    </v-autocomplete>
</template>

<script>
    export default {
        components: {},

        props: {
            value: Object,
            items: Array
        },

        data() {
            return {
                inventoryItem: null,

                isLoading: false,

                search: '',
                searchResults: []
            };
        },

        mounted() {

        },

        watch: {
            value(v) {
                this.product = v;
            },

            inventoryItem(v) {
                this.$emit('input', v);
            }
        },

        methods: {
            focus() {
                this.$refs['field'].focus();
            }
        }
    };
</script>

<style lang="scss" scoped>

</style>
