<template>
    <template-layout>
        <template slot="drawers">
            <process-edit-drawer
                v-model="showEditDrawer"
                :process="selectedProcess"
            >
            </process-edit-drawer>
        </template>

        <template slot="content">
            <v-container>
                <v-toolbar flat dense color="transparent" height="60" class="mb-2">
                    <v-toolbar-title>
                        <span class="headline">Procesos de producción</span>
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-toolbar-title>
                        <v-text-field
                            class="mr-4"
                            v-model="search"
                            label="Buscar..."
                            color="primary"
                            dense hide-details flat prepend-icon="mdi-magnify"
                            clearable
                            style="max-width: 180px;"
                        />
                    </v-toolbar-title>

                    <v-toolbar-title>
                        <!-- <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn class="mr-4" icon @click="showFiltersDrawer = !showFiltersDrawer" v-on="on"><v-icon>mdi-tune</v-icon></v-btn>
                            </template>
                            <span>Filtrar</span>
                        </v-tooltip> -->

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn fab x-small color="primary" class="elevation-0" @click="onCreateSelected" v-on="on"><v-icon>mdi-plus</v-icon></v-btn>
                            </template>
                            <span>Agregar proceso</span>
                        </v-tooltip>
                    </v-toolbar-title>
                </v-toolbar>

                <v-row>
                    <v-col cols="12">
                        <v-card>
                            <v-card-text>
                                <v-data-table
                                    hide-default-footer
                                    :items="processes"
                                    :loading="isLoading"
                                    :headers="headers"
                                    :search="search"
                                    :items-per-page="-1"
                                    sort-by="name"
                                >
                                    <template v-slot:item.yieldProduct="{ item }">
                                        {{ item.yieldAmount }} x {{ item.yieldProduct.name | capitalize }}
                                    </template>

                                    <template v-slot:item.actions="{ item }">
                                        <v-btn icon @click.stop="onEditSelected(item)">
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-btn>

                                        <v-btn icon @click.stop="onProcessSelected(item)">
                                            <v-icon>mdi-play</v-icon>
                                        </v-btn>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </template-layout>
</template>

<script>
    import TemplateLayout from '@/layout/TemplateLayout';
    import ProcessEditDrawer from './ProcessEditDrawer.vue';

    export default {
        components: {
            TemplateLayout,
            ProcessEditDrawer
        },

        data() {
            return {
                showEditDrawer: false,

                selectedProcess: null,

                defaultFilters: {},
                filters: {},

                search: '',

                pagination: {
                    page: 1,
                    itemsPerPage: 100
                },

                headers: [
                    { text: 'Resultado', value: 'yieldProduct' },
                    { text: '', value: 'actions' }
                ]
            };
        },

        computed: {
            isLoading() {
                return this.$store.getters['process/isLoading']();
            },

            totalProcesses() {
                return this.$store.getters['process/getTotal']();
            },

            processes() {
                return this.$store.getters['process/getAll']();
            },

            inventoryItems() {
                return this.$store.getters['inventory/getAll']();
            }
        },

        watch: {
            pagination: {
                handler(val) {
                    this.updateRouteQuery();
                },
                deep: true
            },

            filters: {
                handler(val) {
                    this.updateRouteQuery();

                    this.fetchInventory();
                },

                deep: true
            }
        },

        async mounted() {
            await this.$store.dispatch('products/fetchAll');
            await this.$store.dispatch('inventory/fetchAll');

            this.filters = JSON.parse(JSON.stringify(this.defaultFilters));

            this.updateFiltersFromQuery();
            // this.updateRouteQuery();
        },

        methods: {
            updateFiltersFromQuery() {
                let q = { ...this.$route.query };

                for(let key of Object.keys(this.filters)) {
                    if(q[key]) {
                        this.filters[key] = q[key];
                    }
                }

                if(this.$route.query.search) {
                    this.search = this.$route.query.search;
                }
            },

            updateRouteQuery() {
                let q = {};

                q = { ...this.filters };

                if(this.search) {
                    q.search = this.search;
                }

                this.$router.replace({ name: 'processList', query: q }).catch(() => {});
            },

            async fetchInventory() {
                let filters = {};
                // filters.offset = 0;
                // filters.limit = 1000;

                await this.$store.dispatch('process/fetchAll', filters);
            },

            onProcessSelected(process) {

            },

            onEditSelected(process) {
                this.showEditDrawer = true;
                this.selectedProcess = process;
            },

            onCreateSelected() {
                this.showEditDrawer = true;
                this.selectedProcess = {};
            }
        }
    };
</script>

<style lang="scss" scoped>
</style>
