<template>
    <v-navigation-drawer
        v-model="active"
        right
        temporary
        fixed
        width="600"
    >
        <v-container class="pa-10" v-if="process">
            <v-row align="center">
                <v-col>
                    <v-row>
                        <v-col v-if="process.id" class="py-0">
                            <h4 class="headline blue-grey--text text--darken-3">{{ process.name }}
                                <span class="text--lighten-1 grey--text">#{{ process.id }}</span>
                            </h4>
                        </v-col>

                        <v-col v-else class="py-0">
                            <h4 class="headline blue-grey--text text--darken-3">Agregar proceso</h4>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" class="py-0">
                    <v-subheader>Resultado</v-subheader>
                </v-col>

                <v-col cols="4" class="py-0">
                    <v-text-field
                        label="Cantidad"
                        v-model="fields.yieldQuantity"
                    ></v-text-field>
                </v-col>

                <v-col cols="6" class="py-0">
                    <product-select
                        v-model="fields.yieldProduct"
                        :products="products"
                        autocomplete="please-dont-autofill"
                    />
                </v-col>

                <v-col cols="12" class="py-0">
                    <v-subheader>Ingredientes</v-subheader>
                </v-col>

                <v-col cols="12">
                    <v-simple-table>
                        <tbody>
                            <tr v-for="item in fields.requirementInventoryItems" v-bind:key="item.item.id">
                                <td>{{ item.quantity }}</td>
                                <td>{{ getInventoryItem(item.item).name }}</td>
                            </tr>

                            <tr class="input-row">
                                <td class="px-0">
                                    <v-text-field
                                        ref="quantityField"
                                        label="Cantidad"
                                        v-model="toAddQuantity"
                                        @keydown.enter="add"
                                    ></v-text-field>
                                </td>

                                <td>
                                    <inventory-select
                                        ref="inventoryField"
                                        v-model="toAddInventoryItem"
                                        :items="inventory"
                                        autocomplete="please-dont-autofill"
                                    >
                                    </inventory-select>
                                </td>

                                <td>
                                    <v-btn text icon
                                        ref="addButton"
                                        color="primary"
                                        @click="addInventoryItem(toAddInventoryItem, toAddQuantity)"
                                        :disabled="!toAddInventoryItem || !toAddQuantity"
                                    >
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-container>

        <template v-slot:append>
            <v-row class="ma-4">
                <v-spacer></v-spacer>

                <v-btn color="green darken-1" text @click="onCancelSelected">Cancelar</v-btn>
                <v-btn color="primary" @click="onSaveSelected">Guardar</v-btn>
            </v-row>
        </template>
    </v-navigation-drawer>
</template>

<script>
    import ProductSelect from '@/components/Product/ProductSelect';
    import InventorySelect from '@/components/Inventory/InventorySelect';

    export default {
        components: {
            ProductSelect,
            InventorySelect
        },

        data() {
            return {
                active: false,

                fields: {},

                toAddQuantity: 0,
                toAddInventoryItem: null
            };
        },

        props: {
            value: {
                type: Boolean,
                default: false
            },

            process: {
                type: Object
            }
        },

        computed: {
            products() {
                return this.$store.getters['products/getAll']();
            },

            inventory() {
                return this.$store.getters['inventory/getAll']();
            }
        },

        watch: {
            active(val) {
                this.$emit('input', val);
            },

            value(val) {
                this.active = val;
            },

            process: {
                handler(val) {
                    this.fields = { ...val };
                },
                immediate: true
            }
        },

        methods: {
            getInventoryItem(id) {
                return this.$store.getters['inventory/getById'](id);
            },

            addInventoryItem(item, quantity) {
                if(!this.fields.requirementInventoryItems) {
                    this.fields.requirementInventoryItems = [];
                }

                this.fields.requirementInventoryItems.push({
                    item: item.id,
                    quantity: quantity
                });

                this.toAddInventoryItem = null;
                this.toAddQuantity = null;
            },

            async onSaveSelected() {
                let payload = { ...this.fields };
                payload.yieldProduct = this.fields.yieldProduct.id;

                try {
                    if(this.process.id) {
                        await this.$store.dispatch('process/patch', { inventory: this.inventory, data: payload });
                    }
                    else {
                        await this.$store.dispatch('process/create', { data: payload });
                    }

                    this.active = false;
                }
                catch (err) {
                    console.error(err);
                }
            },

            onCancelSelected(value) {
                this.active = false;
            }
        }
    };
</script>

<style lang="scss" scoped>
</style>
